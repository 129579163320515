import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store';
import { Auth } from 'aws-amplify';
import { navigate, Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FaceIcon from '@material-ui/icons/Face';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Menu';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { USER_ROLES } from '../constants';
import SearchForm from './search-form';
import SearchDrawer from './search-drawer';
import Notification from './notification';
import LanguageSwitcher from './language-switcher';
import { getShowFlag } from '../utils/orders';
import { useOrders } from './orders-context';
import { useSearch } from './search-context';

const useStyles = makeStyles((theme) => ({
  appBar: theme.custom.appBar,
  badge: theme.custom.badge,
  logoAvatar: {
    backgroundColor: '#FFFFFF',
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
  },
  addOrder: {
    marginRight: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: '0.8rem',
    height: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: '#FFF',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
    },
  },
  sectionDesktop: {
    display: 'flex',
  },
  sectionMobile: {
    display: 'flex',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hideOnMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchButton: {
    marginRight: -theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    marginRight: theme.spacing(2),
    color: 'white',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mobileMenuItem: {
    paddingRight: theme.spacing(4),
  },
  languageSwitcher: {
    marginTop: theme.spacing(0.6),
    color: 'white',
  },
  profileMenu: {
    padding: 0,
  }
}));

const getAlertNumber = orders => (orders || []).reduce((acc, o) => getShowFlag(o) ? acc + 1 : acc, 0); 

export default function PrimarySearchAppBar({ auth, path, tenantData }) {
  const classes = useStyles();
  const { t } = useTranslation('header');
  const settings = store.get('settings');
  const storeId = settings && settings.storeId;
  const tenantAccount = settings && settings.tenantAccount;
  const [{newArrived, items: orders}, setOrders] = useOrders();
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = React.useState(null);
  const [search, setSearch] = useSearch();
  const [searchInput, setSearchInput] = useState(search);

  const isSysAdmin = auth && auth.role === USER_ROLES.SYSTEM_ADMIN;
  const isTenOwner = auth && auth.role === USER_ROLES.TENANT_OWNER;
  const isTenAdmin = auth && auth.role === USER_ROLES.TENANT_ADMIN;
  const isAdmin = isSysAdmin || isTenOwner || isTenAdmin; 
  const logoUrl = tenantData && tenantData.details && tenantData.details.logoUrl;

  useEffect(() => {
    setSearchInput(search);
  }, [search]);

  const handleSearchChange = e => {
    // setSearchInput(e.target.value);
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    // setSearchInput('');
    setSearch('');
  };

  const shouldShowSearch = ['/', '/users/'].includes(path);
  const isMobileMenuOpen = Boolean(mobileAnchorEl);
  const isProfileMenuOpen = Boolean(profileAnchorEl);


  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };
  
  const handleMobileMenuOpen = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  
  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleLogout = async () =>{
    await Auth.signOut();
    navigate('/login');
  }

  const alertNumber = getAlertNumber(orders);
  const newArrivedMessage = newArrived > 1
    ? `${newArrived} ${t('newCustomerArrivedPlural')}`
    : t('newCustomerArrived');

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      { !!alertNumber && (
        <MenuItem>
          <IconButton aria-label={`${alertNumber} ${t('ordersNeedAttention')}`} color="inherit" >
            <Badge badgeContent={alertNumber} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p className={classes.mobileMenuItem}>{t('waiting')}</p>
        </MenuItem>
      )}
      
      <MenuItem onClick={handleMobileMenuClose} component={Link} to="/">
        <IconButton aria-label="orders" color="inherit">
          <ListAltIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>{t('orders')}</p>
      </MenuItem>
      {isAdmin && (
        <MenuItem onClick={handleMobileMenuClose} component={Link} to="/users">
          <IconButton aria-label="users" color="inherit">
            <FaceIcon />
          </IconButton>
          <p className={classes.mobileMenuItem}>{t('users')}</p>
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem onClick={handleMobileMenuClose} component={Link} to="/stores">
          <IconButton aria-label="stores" color="inherit">
            <StorefrontIcon />
          </IconButton>
          <p className={classes.mobileMenuItem}>{t('stores')}</p>
        </MenuItem>
      )}
      {isSysAdmin && (
        <MenuItem onClick={handleMobileMenuClose} component={Link} to="/select-tenant">
          <IconButton aria-label="logout" color="inherit">
            <VpnKeyIcon />
          </IconButton>
          <p className={classes.mobileMenuItem}>
            {t('selectTenant')}
            { tenantAccount && <Typography variant="caption" color="textSecondary"><br/>{ tenantAccount }</Typography> }
          </p>
        </MenuItem>
      )}
      <MenuItem onClick={handleMobileMenuClose} component={Link} to="/select-store">
        <IconButton aria-label="logout" color="inherit">
          <LocationOnIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>
          {t('selectStore')}
          { storeId && <Typography variant="caption" color="textSecondary"><br/>{ storeId }</Typography> }
        </p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton aria-label="logout" color="inherit">
          <PowerSettingsNewIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>
          {t('logOut')}  
          { auth && <Typography variant="caption" color="textSecondary"><br/> @{ auth.username }</Typography> }
        </p>
      </MenuItem>
    </Menu>
  );

  const profileMenuId = 'primary-search-account-menu-profile';
  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      {isSysAdmin && (
        <MenuItem onClick={handleMobileMenuClose} component={Link} to="/select-tenant">
          <IconButton aria-label="logout" color="inherit">
            <VpnKeyIcon />
          </IconButton>
          <p className={classes.mobileMenuItem}>
            {t('selectTenant')}
            { tenantAccount && <Typography variant="caption" color="textSecondary"><br/>{ tenantAccount }</Typography> }
          </p>
        </MenuItem>
      )}
      <MenuItem onClick={handleProfileMenuClose} component={Link} to="/select-store">
        <IconButton aria-label="logout" color="inherit">
          <LocationOnIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>
          {t('selectStore')}
          { storeId && <Typography variant="caption" color="textSecondary"><br/>{ storeId }</Typography> }
        </p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton aria-label="logout" color="inherit">
          <PowerSettingsNewIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>
          {t('logOut')}  
          { auth && <Typography variant="caption" color="textSecondary"><br/> @{ auth.username }</Typography> }
        </p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      { newArrived && <Notification open message={newArrivedMessage} onClose={() => setOrders({clearArrived: true})} /> }
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {auth && (
            <div className={classes.sectionMobile}>
              <IconButton
                edge="start"
                aria-label="open menu"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Badge badgeContent={alertNumber} color="secondary">
                  <MenuIcon />
                </Badge>
              </IconButton>
            </div>
          )}
          <Link to="/">
            <Avatar alt="Logo" variant="rounded" size="small" src={logoUrl} className={classes.logoAvatar} />
          </Link>
          {auth && shouldShowSearch && (
            <div className={classes.hideOnMobile}>
              <SearchForm onChange={handleSearchChange} onClear={handleClearSearch} value={searchInput} />
            </div>
          )}
          
          <div className={classes.grow} />
          { auth && (
            <div className={classes.sectionDesktop}>
              <Button component={Link} to="/" className={classes.link}>{t('orders')}</Button>
              { isSysAdmin && <Button component={Link} to="/tenants" className={classes.link}>{t('tenants')}</Button> }
              { isAdmin && <Button component={Link} to="/users" className={classes.link}>{t('users')}</Button> }
              { isAdmin && <Button component={Link} to="/stores" className={classes.link}>{t('stores')}</Button> }
              { storeId && (
                <Button 
                  className={classes.addOrder}
                  color="inherit" 
                  component={Link} 
                  size="small"
                  variant="outlined" 
                  to="/orders/add"
                >
                  {t('addOrder')}
                </Button>
              )}
              <SearchDrawer 
                className={classes.searchButton}
                onChange={handleSearchChange}
                onClear={handleClearSearch}
                value={searchInput}
              />
              { !!alertNumber && (
                <Tooltip title={`${alertNumber} ${t('ordersNeedAttention')}`} aria-label={`${alertNumber} ${t('ordersNeedAttention')}`}>
                  <IconButton aria-label={`${alertNumber} ${t('ordersNeedAttention')}`} color="inherit" className={classes.hideOnMobile} >
                    <Badge badgeContent={alertNumber} color="secondary" classes={{ badge: classes.badge }}>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}

              <MenuItem onClick={handleProfileMenuOpen} className={classes.profileMenu}>
                <IconButton
                  aria-label="Account"
                  aria-controls={profileMenuId}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </MenuItem>
            </div>    
          )}
          <LanguageSwitcher classes={classes.languageSwitcher} />
        </Toolbar>
      </AppBar>
      <Toolbar />
      {renderMobileMenu}
      {renderProfileMenu}
    </div>
  );
}
