/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import i18n from './i18n';
import { SearchProvider } from './search-context';
import { OrdersProvider } from './orders-context';
import theme from '../theme';
import Header from './header'
import Copyright from './copyright';
import './layout.css'
import useTenantData from './use-tenant-data';
import Loading from './loading';

const Layout = ({ auth, path, children }) => {
  const { tenantData, loading } = useTenantData();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (loading) return <Loading />

  return (
    <ThemeProvider theme={theme(tenantData)}>
      <SearchProvider>
        <OrdersProvider>
          <CssBaseline />
          <Header auth={auth} path={path} siteTitle={data.site.siteMetadata.title} tenantData={tenantData} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1280,
              padding: `0 1.0875rem 1.45rem`,
            }}
          >
            <main>{children}</main>
            <Copyright />
          </div>
        </OrdersProvider>
      </SearchProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
