import React, { 
  createContext, 
  useContext, 
  useEffect,
  useMemo,
  useRef,
  useState,
 } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store';
import { Auth } from 'aws-amplify';
import { EP, callApi } from '../api';
import { ORDER_STATUS } from '../constants';

const OrdersContext = createContext();

const useOrders = () => {
  const context = useContext(OrdersContext);
  if (!context) {
    throw new Error(`useOrders must be used within a OrdersProvider`);
  }
  return context;
};

const OrdersProvider = props => {
  const [orders, setOrders] = useFetchOrders();
  const value = useMemo(() => [orders, setOrders], [orders]);
  return <OrdersContext.Provider value={value} {...props} />;
};

const getNewArrived = (prevValue, prevItems, newItems) => {
  if (!prevItems.length) return 0;
  const prevArrived = prevItems.filter(o => o.status === ORDER_STATUS.customerArrived).length;
  const currentArrived = newItems.filter(o => o.status === ORDER_STATUS.customerArrived).length;
  return prevValue + (currentArrived - prevArrived);
}

const getUser = async (setUser) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) setUser(user);
  } catch(e) {}
}

const useFetchOrders = () => {
  const { t } = useTranslation('ordersList');
  const ordersInitialState = {
    items: [],
    isLoading: false,
    error: null,
    newArrived: 0,
  };
  const [user, setUser] = useState();
  const [orders, setOrders] = useState(ordersInitialState);
  const [timer, setTimer] = useState();
  const [timer2, setTimer2] = useState();
  const ordersRef = useRef(orders);
  ordersRef.current = orders;

  !user && getUser(setUser);

  const publicSetOrders = ({ items, clearArrived }) => setOrders(Object.assign(
    {},
    orders, 
    items ? { items } : {},
    clearArrived ? { newArrived: 0 } : {},
  ));

  async function fetchOrders() {
    const { current } = ordersRef;
    const settings = store.get('settings');
    if (settings && settings.storeId) {
      try {
        setOrders({ ...current, error: null });
        if (!current.items.length) setOrders({ ...current, isLoading: true });
        const { open, closed } = await callApi(EP.orders.list);
        const newArrived = getNewArrived(current.newArrived, current.items, open.results);
        setOrders({ ...ordersInitialState, items: [...open.results, ...closed.results], newArrived });
      } catch (e) {
        console.log(e);
        setOrders({ ...current, isLoading: false, error: t('errorFetchingOrders') });
      } 
    }
  }
  useEffect(() => {
    const FETCH_ORDERS_EVERY_MS = process.env.GATSBY_FETCH_ORDERS_EVERY_MS || 6000 * 5;
    if (user && !timer) {
      fetchOrders();
      setTimer(setInterval(function() {
        fetchOrders();
      }, FETCH_ORDERS_EVERY_MS));
    }
    return () => timer && clearInterval(timer);
  }, [user, timer]);
  useEffect(() => {
    const TICK_EVERY_MS = process.env.TICK_EVERY_MS || 1000;
    if (user && !timer2) {
      setTimer2(setInterval(function() {
        console.log('Healthcheck')
      }, TICK_EVERY_MS));
    }
    return () => timer2 && clearInterval(timer2);
  }, [user, timer]);
  return [orders, publicSetOrders];
}

export { OrdersProvider, useOrders };
