import { ORDER_STATUS } from '../constants';

const getDiffInDays = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
}

export const getTs = (order, status) => {
  const history = status
    ? (order.history || []).filter(o => o.to === status)
    : order.history || [];
  const last = history[history.length - 1] || {};
  return last.modifiedAt;
}

export const getLocaleTs = (order, status) => {
  const ts = getTs(order, status);
  const now = new Date();
  const diffInDays = ts ? getDiffInDays(ts, now) : 0;
  const localeTs = ts ? new Date(ts).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : '';
  return diffInDays ? `${localeTs} (+${diffInDays}D)` : localeTs;
}

// export const getLocationFailed = order => (
//   order.status === ORDER_STATUS.customerNotified
//   && order.locationFailed
// );

// export const getOrderIsLate = order => (

// )

export const getShowFlag = (order) => {
  if ([ORDER_STATUS.delivered, ORDER_STATUS.cancelled].includes(order.status)) return;
  if (order.status === ORDER_STATUS.customerNotified) return order.locationFailed;
  const now = new Date().getTime();
  const totalMinutes = Math.round((now - getTs(order))/(1000 * 60));
  return totalMinutes > 10;
}

