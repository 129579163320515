import React from "react"
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { languages } from './i18n';

export default function LanguageSwitcher({ classes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation('heading');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  } 

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}  className={classes}>
        { (i18n.language).split('-')[0] } &#9660;
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
         { languages.map(({ code, label }) => <MenuItem key={code} onClick={() => handleChangeLanguage(code)}>{label}</MenuItem>) } 
      </Menu>
    </div>
  );
}
