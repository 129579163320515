import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SearchForm from './search-form';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function TemporaryDrawer({ className, onChange, onClear, value }) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  

  return (
    <div className={className}>
      <React.Fragment>
        { !state && (
          <IconButton
          aria-label="open search"
          aria-haspopup="true"
          onClick={toggleDrawer(true)}
          color="inherit"
          >
            <SearchIcon />
          </IconButton>
        )}
        
        <Drawer anchor={'top'} open={state} onClose={toggleDrawer(false)}>

        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <SearchForm 
                onChange={onChange} 
                onClear={onClear}
                onPressEnter={toggleDrawer(false)}
                value={value}
              />
              <IconButton
                edge="end"
                aria-label="close search"
                onClick={toggleDrawer(false)}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
          
        </Drawer>
      </React.Fragment>
    </div>
  );
}
