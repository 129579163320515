import React, { 
  createContext, 
  useContext, 
  useState,
 } from 'react';

const SearchContext = createContext();

const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error(`useSearch must be used within a SearchProvider`);
  }
  return context;
};

const SearchProvider = props => {
  const [search, setSearch] = useState('');
  const value = React.useMemo(() => [search, setSearch], [search])
  return <SearchContext.Provider value={value} {...props} />
};

export { SearchProvider, useSearch };
