import React from 'react';
import { useTranslation } from 'react-i18next';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  clearButton: {
    padding: theme.spacing(0, 2, 0, 0),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    right: 0,
    top: 0,
  },
  clearIcon: {
    width: '0.7em',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    },
  },
}));

export default function SearchForm({ onChange, onClear, value, onPressEnter }) {
  const { t } = useTranslation('header');
  const classes = useStyles();
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onPressEnter && onPressEnter(e);
    }
  }
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={`${t('filter')}...`}
        autoFocus
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
      <Button className={classes.clearButton} onClick={onClear}>
        <CancelIcon color="primary" className={classes.clearIcon} />
      </Button>
    </div>
  );
}
